<template>
  <div
    class="nav-item dropdown d-none d-md-flex me-3"
    v-if="$auth.companyList.length > 1"
  >
    <select v-model="selectedCompany" @change="selectNewCompany">
      <option
        v-for="company in $auth.companyList"
        v-bind:key="company.companyId"
        :value="company.companyId"
      >
        {{ company.companyName }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "ActiveCompany",
  data: () => {
    return {
      selectedCompany: null,
    };
  },
  methods: {
    selectNewCompany() {
      this.$auth.saveActiveCompany(this.selectedCompany);
      this.$router.go();
    },
  },
  mounted() {
    this.selectedCompany = this.$auth.activeCompany.companyId;
  },
};
</script>
