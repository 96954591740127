<template>
  <b-modal
    id="admin-settings-modal"
    title="Settings"
    v-if="$auth.superuser"
    @show="loadReps"
  >
    <label class="form-check form-switch mb-0">
      <input
        class="form-check-input"
        type="checkbox"
        :checked="$auth.settings.maskedMode"
        @change="$auth.updateMaskedMode"
      />
      <span class="form-check-label">Masked Mode</span>
    </label>
    <hr />
    <a href="/admin/insights">Admin: Activity Report</a><br />
    <a href="/admin/subscriptions">Admin: Subscription Report</a>
    <hr />
    <strong>AE Links</strong>
    <a href="/newupgrade?trial=1&defaultPlan=monthly">
      Free Trial Upgrade Screen
    </a>
    <hr />

    <label class="form-label" for="whichae">Which AE are you?</label>
    <select v-model="aeSelect" id="whichae">
      <option :value="null">None</option>
      <option v-for="ae in reps" :key="`ae-${ae.userid}`" :value="ae.userid">
        {{ ae.fullname }}
      </option>
    </select>
  </b-modal>
</template>

<script>
import AccountExecutiveService from "../../../services/AccountExecutiveService";
export default {
  name: "admin.settings.settings-modal",
  data() {
    return {
      reps: [],
      aeSelect: null,
    };
  },
  watch: {
    aeSelect(newer, old) {
      if (this.aeSelect) {
        window.localStorage.setItem("demo-as-rep", this.aeSelect);
      } else {
        if (old && !newer) {
          window.localStorage.removeItem("demo-as-rep");
        }
      }
    },
  },
  methods: {
    async loadReps() {
      if (window.localStorage.getItem("demo-as-rep")) {
        this.aeSelect = parseInt(window.localStorage.getItem("demo-as-rep"));
      }
      this.reps = await AccountExecutiveService.getAllAE();
    },
  },
};
</script>
