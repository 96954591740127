<template>
  <div class="dropdown-container" v-click-outside="close" @click="toggle">
    <slot name="dropdown-toggle">
      <a href="#" @click.prevent="" class="nav-link dropdown-toggle">
        <span class="nav-link-icon d-md-none d-lg-inline-block">
          <slot name="dropdown-icon"></slot>
        </span>
        {{ label }}
      </a>
    </slot>
    <dropdown-menu :position="position" :show="show">
      <slot></slot>
    </dropdown-menu>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-container {
  z-index: 1025;
}
</style>

<script>
import ClickOutside from "../../../directives/ClickOutSide";
import DropdownMenu from "./DropdownMenu.vue";
export default {
  name: "dropdown",
  props: {
    label: { default: "Dropdown" },
    position: { default: "left" },
  },
  data: () => ({
    show: false,
  }),
  methods: {
    toggle() {
      this.show = !this.show;
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
  directives: { ClickOutside },
  components: {
    DropdownMenu,
  },
};
</script>
