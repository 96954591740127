<template>
  <a href="#" class="dropdown-item">
    <slot>
      <span v-if="!!badge" class="float-right">
        <span :class="`badge badge-${type}`">{{ badge }}</span>
      </span>
      {{ label }}
    </slot>
  </a>
</template>

<script>
export default {
  name: "dropdown-menu-item",
  props: {
    label: { default: "Dropdown Item" },
    badge: { default: false },
    type: { default: "primary" },
  },
};
</script>

<style scoped></style>
